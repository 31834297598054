/* Styles from the card carousel */
/* .carousel .slide img {
  width: 150px !important;
} */

.carousel .slide {
  text-align: left !important;
}

ul.control-dots {
  margin: 0 !important;
}

.marker-label {
  margin-top: -30px;
  font-weight: 700;
  -webkit-text-stroke: 0.5px #fff;
}

/* Font Soleil */
@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilRegular.otf") format("truetype");
  font-weight: normal;
  descent-override: 0%;
  ascent-override: 80%;
}

input::placeholder {
  overflow: visible;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilBold.otf") format("truetype");
  font-weight: bold;
  descent-override: 0%;
  ascent-override: 80%;
}
@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilLight.otf") format("truetype");
  font-weight: lighter;
  descent-override: 0%;
  ascent-override: 80%;
}

iframe {
  margin: 0 !important;
  width: 100% !important;
}
